import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
		    selectedFile: null,
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			maintabFontSize : '',
			menuFontSize : '',
			primaryFontSize : '',
			letterSpacing : '',
			lineHeight : '',
			font_type:'google_font',
			fontweight:'',
			file_name: '',
			file_preview: '',
			fontFile: '',
			file_fileExtension:'',
			varientCount : 0,
			varient_name : [],
			varient_name_default : 'light-300',
			selectedFileCount : 0,

			bookDetails: [
				{
				  index: Math.random(),
				  varient_name: "",
				  varient_id : "",
				  font_file: [],
				  
				}
			  ],


		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleFileChange = this.handleFileChange.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('Font Add');
    }

  /*onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
	  
	  this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })	
		
     
    }; */
	
	// onFileChange = (e) =>{
	// this.fileObj = [];
    //     this.fileObj.push(e.target.files)
    //     for (let i = 0; i < this.fileObj[0].length; i++) {
    //         this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    //         //this.fileArray.push(this.fileObj[0][i])
    //         this.imageArray.push(this.fileObj[0][i])

    //     }
    //     this.setState({ font_file: this.imageArray })		
    // }

	// onChangeHandler=event=>{
	// 	let reader = new FileReader();
	// 	const file = event.target.files[0];
	// 	const extension = file.name.split('.').pop();
	// 	reader.onloadend = () => {
	// 	this.setState({
	// 		file_name: file.name,
	// 		file_preview: reader.result,
	// 		fontFile: file,
	// 		file_fileExtension:extension,
	// 	});
	// 	};
	// 	reader.readAsDataURL(file);
	// }	

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					//selectedFile : formPayload.selectedFile,
					status : status,
					fontweight : formPayload.fontweight,
					maintabFontSize : formPayload.maintabFontSize,
					menuFontSize : formPayload.menuFontSize,
					primaryFontSize : formPayload.primaryFontSize,
					letterSpacing : formPayload.letterSpacing,
					lineHeight : formPayload.lineHeight,
					font_type:formPayload.font_type,
					fontVariantDefault: formPayload.varient_name_default,
					bookDetails:JSON.stringify(formPayload.bookDetails),
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				// var filesdata = this.state.font_file;
				// for (var i in filesdata) {
				//  formData.append('font_file[]',filesdata[i])
				// }	
				
				// var filesdata = formPayload.fontFile;
				// formData.append('font_file',filesdata);	
				var bookDetails = this.state.bookDetails;
				for (var i in bookDetails) {
					if(bookDetails.length != 0){
						var fontfile = bookDetails[i]['font_file'+i];
						for(var j in fontfile){
							//console.log(bookDetails[j]['font_file'+i], '--formData2');
							formData.append('font_file'+i+'[]',fontfile[j])
						}				 
					}
				}	
				
				axios.post(apiUrl+"font/add",formData,config).then(res => {
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');

						scrollToTop();
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/fonts');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						scrollToTop();
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {fontname,fontfamilyname,selectedFile,fonturl,fontweight,maintabFontSize,menuFontSize,primaryFontSize,font_type,font_file,letterSpacing,lineHeight, file_name, bookDetails} = this.state;

		let errors = 0;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		if (font_type == 'google_font') {
			if (!fonturl) {
				errors++;
				$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfonturl').html('');
			}
		}else if(font_type == 'normal_font'){
			// if(font_file.length==0){
			// 	errors++;
			// 	$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			// }else{
			// 	$('.errorfile').html('');	
			// }
			// if(!file_name){
			// 	errors++;
			// 	$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			// }else{
			// 	$('.errorfile').html('');	
			// }

			// if (!fontweight) {
			// 	errors++;
			// 	$('.errorfontweight').html('<span class="errorspan">Please fill the field</span>');
			// }else{
			// 	$('.errorfontweight').html('');
			// }
			//console.log(bookDetails, '--bookDetails');
			if(bookDetails.length != 0){
				for (var i in bookDetails) {					
					var fontfile = bookDetails[i]['font_file'+i];
					//console.log(fontfile, '--fontfile');
					if(fontfile != undefined){
						if(fontfile.length==0){
							errors++;
							$('.errorfile'+i).html('<span class="errorspan">Please upload files</span>');
						}else{
							$('.errorfile'+i).html('');	
						}		 					
					} else{
						errors++;
						$('.errorfile'+i).html('<span class="errorspan">Please upload files</span>');
					}
				}	
			}
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.fontadd !== this.props.fontadd){
    		if(Object.keys(Props.fontadd).length > 0){
				this.setState({Loading:false});
					if(Props.fontadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.fontadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/fonts');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.fontadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

	  addMoreFunc = (e) => {
		//this.setState({ 'selectedFileCount': 0 });
		return this.state.bookDetails.map((val, idx) => {
			let name = `font_file_${idx}`,
				varient = `varient_${idx}`,
				font_file = `font_file${idx}`,
				errorfile = `errorfile${idx}`
			return (
			  <>
			  <div className='form-left'>
				<div className="form-group"  >
					<label>Font Variant </label>						
					<div className='d-flex align-items-center gap-3'>
					<select name="varient_name" className="form-control" id={varient} data-id={idx} onChange={(e) => this.handleFileChange(e, idx)} defaultValue={this.state.varient_name_default}>
						<option value="light-300" className="fontVarientName" selected> Light 300</option>
						<option value="regular-400" className="fontVarientName" > Regular 400</option>
						<option value="medium-500" className="fontVarientName" > Medium 500</option>
						<option value="bold-700" className="fontVarientName" > Bold 700</option>								
						<option value="semibold-600" className="fontVarientName" > Semi Bold 600</option>
						<option value="extrabold-800" className="fontVarientName" > Extra Bold 800
						</option>
						<option value="black-900" className="fontVarientName" > Black 900</option>
					</select>	
					 {idx === 0 ? (
					<span className="add_font_varients cursor-pointer"  onClick={() => this.addFunc()}><i class="fa fa-plus-circle fa-2x"></i></span> ) : 
					<span className="btn_remove cursor-pointer" onClick={() => this.deleteFunc(val)} ><i class="fa fa-trash fa-2x"></i></span>
					}				
					</div>		
				</div>
			  </div>

			  <div className='form-right'>
				<div className="form-group">
					<label>Upload {this.state.fontname} font files (eot|ttf|woff|woff2) <span class="required" style={{ color: "red" }} > * </span> :</label>
					<div class="choose-file">
					<input type="file" name={font_file} 
					onChange={(e) => this.handleFileChange(e, idx)} 
					//onChange={this.onFileChange}
					data-id={idx}
					id={font_file} multiple   /> 	
					</div>					
					<p className={font_file}></p> 
					<div className={errorfile}></div> 						
				</div>	
				</div>
			  </>
			);
		  });
	}
	addFunc = e => {
		this.setState(prevState => ({
		  bookDetails: [
			...prevState.bookDetails,
			{
			  index: Math.random(),
			  varient_name: "",
			  varient_id : "",
			  font_file: [],
			}
		  ]
		}));
	  };
	
	//   deteteRow = index => {
	// 	this.setState({
	// 	  bookDetails: this.state.bookDetails.filter(
	// 		(s, sindex) => index !== sindex
	// 	  )
	// 	});
	//   };
	
	  deleteFunc(record) {
		this.setState({
		  bookDetails: this.state.bookDetails.filter(r => r !== record)
		});
	  }
	  handleFileChange = (e, idx) => {
		let	font_file = `font_file${idx}`;
		let bookDetails = [...this.state.bookDetails];
		bookDetails[e.target.dataset.id]['varient_id'] = idx;
		if (
		  ["varient_name"].includes(
			e.target.name
		  )
		) {
		  let bookDetails = [...this.state.bookDetails];
		  bookDetails[e.target.dataset.id][e.target.name] = e.target.value;
		} 
		else if(e.target.name == font_file){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))					
				this.imageArray.push(this.fileObj[0][i])
			}
			let bookDetails = [...this.state.bookDetails];
			//console.log(this.imageArray, idx, '--e.target.dataset.id');
			  bookDetails[e.target.dataset.id][font_file] = this.imageArray;

			if(this.imageArray.length > 1){
				var countMsg = this.imageArray.length+' files';
			} else if(this.imageArray.length == 1){
				var countMsg = this.imageArray.length+' file';
			} else{
				var countMsg = '';
			}
			$(`.font_file${idx}`).text(countMsg);
			  //this.setState({ 'selectedFileCount': this.imageArray.length });
			  
			this.imageArray = [];
			//this.setState({ font_file: this.imageArray })	
		}
		else {
			this.setState({ [e.target.name]: e.target.value });
		}

		
	  };

  render() {
  	const {selectedOption,font_type,font_file} = this.state;
	 //console.log(this.state.bookDetails, '--font_file');	 

    return (
      <div className="wrapper"> 
	 	<Header />
    	<MenuSidebar currentpage="fonts" />  
		<div className="content">	
			<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
				<div className="form-wrapper leaa-admin-head card">
					<div className="success_message"></div>
					<div>
						<a className="primary-btn" href="/fonts">Back</a>
					</div>
					<div className="title">
						<h4>Add Font</h4>
					</div>
					<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>			
						<div className="form-row">
							<div className='form-left'>
								<div className="form-group">
									<label>Font Name<span class="required" style={{ color: "red" }} > * </span></label>
									<input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
									<div className="errorfont_name"></div>
								</div>
							</div>
							<div className='form-right'>
								<div className="form-group">
									<label>Exact Family Name<span class="required" style={{ color: "red" }} >*</span></label>
									<input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
									<div className="errorfontfamilyname"></div>
								</div>
							</div>
						</div>
									
						<div className="form-row">
							<div className='form-left'>
								<div className="form-group">					
									<label>Font type<span class="required" style={{ color: "red" }} > * </span> :</label>
									<div className="font-check-row">
										<label className="checkbox-inline">
											<input type="radio" name='font_type' value="google_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'google_font') ? 'checked' : '' } /> 
											<span>Google Font</span>
										</label>
										<label className="checkbox-inline">
											<input type="radio" name='font_type' value="normal_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'normal_font') ? 'checked' : '' } />
											<span>Normal Font</span>
										</label>
									</div>
									<div className="error_font_type"></div>
								</div> 
							</div>
							<div className={this.state.font_type == 'google_font'?"form-right":"hide-font-type"}>
								<div className="form-group">
									<label>Font Import url<span class="required" style={{ color: "red" }} > * </span> :</label>								
									<input type="text" name="fonturl" onChange={this.handleInputChange} className="form-control" value={this.state.fonturl}	 autoComplete="off" />

									<div className="errorfonturl"></div>
								</div>						
							</div>
						</div>
						<div className="form-row">
							<div className='form-left'>
								<div className="form-group">
									<label>Main Tab Font Size</label>
									<input type="text" name="maintabFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.maintabFontSize} autoComplete="off" />
									<div className="errormaintabFontSize"></div>
								</div>
							</div>

							<div className='form-right'>
								<div className="form-group">
									<label>Menu Font Size</label>
									<input type="text" name="menuFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.menuFontSize} autoComplete="off" />
									<div className="errormenuFontSize"></div>
								</div>
							</div>
						</div>

						<div className="form-row">
							<div className='form-left'>
								<div className="form-group">
									<label>Primary Font Size</label>
									<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
									<div className="errorprimaryFontSize"></div>
								</div>
							</div>

							<div className='form-right'>
								<div className="form-group">
									<label>Letter Spacing</label>
									<input type="text" name="letterSpacing" onChange={this.handleInputChange} className="form-control" value={this.state.letterSpacing} autoComplete="off" />
									<div className="errorletterSpacing"></div>
								</div>
							</div>
						</div>
						<div className="form-row">
							<div className='form-left'>
								<div className="form-group">
									<label>Line Height</label>
									<input type="text" name="lineHeight" onChange={this.handleInputChange} className="form-control" value={this.state.lineHeight} autoComplete="off" />
									<div className="errorlineHeight"></div>
								</div>
							</div>
							<div className='form-right'>
								<div className="form-group">					
									<label>Status</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
										options={lang.common.status_option} 
										onChange={this.handleChange}
										/>
								</div>
							</div>
						</div>
						<div className={this.state.font_type == 'normal_font'?"form-row ":"hide-font-type"} id="fontFile">		
								{this.addMoreFunc()}							
						</div>				
						<div className='white-space-110px'></div>
					
						<div className="btn-group export">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true && <span className="load-data"></span> }Submit
						</button>
						</div>
					</form>
				</div>
			</div>	
		</div>
    </div>
    );
  }
}



export default withRouter(Add);