import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Table
} from "react-bootstrap";
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

/* import { GET_FONTLIST, GET_DELETEFONT } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      disputelist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      selectid:'',
    };

    

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('raiseDispute');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
      this.listloadData()
   }
listloadData(postobject=''){
  var admin_id = localStorage.getItem("admin_id");
  var qs = require('qs');
  if(postobject!=''){
    var postobject=postobject;
  }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id
    };
  }
  
axios.post(apiUrl+"raisedispute/list",qs.stringify(postobject)).then(res => {
 
 if(res.data.status === 'success'){
  this.setState({ totalRecord : res.data.records_count, disputelist: res.data.disputelist}, function() {
    this.disputelist();
  });
 }
}); 
}
   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      this.listloadData(postobject)
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
      activePage: 1,
      search_all: value,
      admin_id: admin_id
    };
    this.listloadData(postobject)
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.listloadData(postObject)
        
    }
    confirmPopup = (id) => {
      var status = status;
      if(status == 'inactive'){
        status = "active";
      }else{
        status = "inactive";
      }
      this.setState({selectid: id})	
      $('.confirm-action').addClass('show');		
    }
    
    Confirmstatus = (confirmstatus) => {
      if(confirmstatus == 'yes'){
        this.deletedispute();
        $('.confirm-action').removeClass('show');
      }else{
        $('.confirm-action').removeClass('show');	
      }
        
    }


    deletedispute(){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          dispute_id  :this.state.selectid,
          admin_id  : admin_id
        };

      axios.post(apiUrl+"raisedispute/delete",qs.stringify(postObject)).then(res => {
     
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
          setTimeout(
          function() {
          $('.success_message').html('');
          }
          .bind(this),
          3000
          );

          this.listloadData()
            }
      }); 
}
  disputelist() {
    var disputelist = this.state.disputelist;
    if (disputelist != "undefined" && disputelist != null) {
        if (Object.keys(disputelist).length > 0) {
          const disputelistDetails = disputelist.map(
            (disputelist, Index) => {
              let sno = Index+1;
              if(disputelist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={disputelist.id}>
                <td>{sno}</td>
                  <td>
                  {disputelist.name}
                  </td>
                  <td>{status}</td>
                  <td>
                   <Link to={{ pathname: '/raiseDispute-edit/'+disputelist.id}} className="" title="edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                     <Link onClick={this.confirmPopup.bind(this,disputelist.id)} className="" title="delete">  <i className="ft-trash-2" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return disputelistDetails ;
        }  else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
  <div className="confirm-action">
			<p>Are you sure! you want to delete the dispute?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="raiseDispute" />  
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
            <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>{this.state.totalRecord} Raise Dispute{this.state.totalRecord>1 ? 's' : ''}</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button">  
                    <a className="" href='/raiseDispute-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.disputelist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}


export default List;