 /*local */ 
// export const apiUrl      = "http://localhost/rehab/api/";
// export const baseUrl      = "http://localhost/rehab";
// export const mediaUrl      = "http://localhost/rehab/media/";
// export const sampleFileUrl      = "http://localhost/rehab/media/sample_file/";
// export const cmscontentURL       = "http://localhost/rehab/media/cmscontent";
// export const tapURL       = "http://localhost/rehab/media/tabmanagement";
// export const amenityURL       = "http://localhost/rehab/media/amenities";
// export const rehabURL       = "http://localhost/rehab/media/rehabs";

/*Live */

export const apiUrl      = "https://admin.we.rehab/rehab/api/";
export const baseUrl      = "https://admin.we.rehab/rehab";
export const mediaUrl      = "https://admin.we.rehab/rehab/media/";
export const sampleFileUrl      = "https://admin.we.rehab/rehab/media/sample_file/";
export const cmscontentURL       = "https://admin.we.rehab/rehab/media/cmscontent"; 
export const categoryURL       = "http://admin.we.rehab/rehab/media/category";
export const amenityURL       = "http://admin.we.rehab/rehab/media/amenities";
export const tapURL       = "http://admin.we.rehab/rehab/media/tabmanagement";
export const rehabURL       = "http://admin.we.rehab/rehab/media/rehabs";

