import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import moment from 'moment';
/* import { GET_VIEWUSERLIST } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      userid: userid,
      couponlistview:'',
      familtlistview:'',
      sendcouponslisting:[],
    };

    axios.get(apiUrl+"coupon/listdetail?id="+userid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.couponlist;
        
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({couponlistview:res.data.couponlist,sendcouponslisting:res.data.sendcouponlist});
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Coupon View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
    
    const navigate = this.props.navigate;
  

    if(this.state.couponlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="coupon" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
              
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Coupon Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Coupon Code<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.couponlistview.coupon_code}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Discount<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>${this.state.couponlistview.discount}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Quantiy<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.couponlistview.qty}</p>
                          </div>
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Valid From<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                               <p>{moment(this.state.couponlistview.valid_from).format('MMM DD YYYY hh:mm A')}</p>
                              {/* <p>{`${moment(this.state.couponlistview.valid_from).format('MMM DD YYYY hh:mm A')} to ${moment(this.state.couponlistview.valid_to).format('MMM DD YYYY hh:mm A')}`}</p> */}
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Valid Till<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                               <p>{moment(this.state.couponlistview.valid_to).format('MMM DD YYYY hh:mm A')}</p>
                              
                          </div>
                        </div>
                       
                      
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                    </div>
                </div> 

                <div className="title">
                    <h3>Coupons Availed Users</h3>
                </div>
                <div className="car-listing-row">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>User</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Coupon Sent On</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sendcouponslisting.length > 0 ? (
                        this.state.sendcouponslisting.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{user.firstname}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{(user.coupon_sending_date != null ) ? moment(user.coupon_sending_date).format('MMM DD, YYYY hh:mm A') : ''}</td>
                            <td></td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No users have been sent this coupon yet.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  {/* <div className="text-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalRecord}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </div> */}
                </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
