import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';



import './common/css/font-awesome.min.css';

import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";



import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";
import UserViewAppt from "./components/User/ViewApptsList";

import RehabAdd from "./components/Rehab/Add";
import RehabList from "./components/Rehab/List";
import RehabEdit from "./components/Rehab/Edit";
import RehabView from "./components/Rehab/View";
import RehabViewAppt from "./components/Rehab/ViewApptsList";

import DriverAdd from "./components/Driver/Add";
import DriverList from "./components/Driver/List";
import DriverEdit from "./components/Driver/Edit";
import DriverView from "./components/Driver/View";
import DriverViewAppt from "./components/Driver/ViewApptsList";

import AppointmentList from "./components/Appointment/List";
import AppointmentView from "./components/Appointment/View";
import AppointmentAdd from "./components/Appointment/Add";
import AppointmentAssigned from "./components/Appointment/AssignedList";
import AppointmentObservation from "./components/Appointment/ObservationList";
import AppointmentAssignedView from "./components/Appointment/AssignedView";
import AppointmentObservationView from "./components/Appointment/ObservationView";

import MemList from "./components/Membership/List";
import MemEdit from "./components/Membership/Edit";
import MemAdd from "./components/Membership/Add";
import MemView from "./components/Membership/View";

import MembershipCategoryList from "./components/MembershipCategory/List";
import MembershipCategoryAdd from "./components/MembershipCategory/Add";
import MembershipCategoryEdit from "./components/MembershipCategory/Edit";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";

import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import DashboardList from "./components/Dashboard/List";
import Gallery from "./components/Gallery/Gallery";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqImport from "./components/Faq/ImportFaq";

import AmenityList from "./components/Amenities/List";
import AmenityAdd from "./components/Amenities/Add";
import AmenityEdit from "./components/Amenities/Edit";

import VehicleList from "./components/Vehicles/List";
import VehicleAdd from "./components/Vehicles/Add";
import VehicleEdit from "./components/Vehicles/Edit";

import AiFeedList from "./components/AiFeed/List";
import AiImport from "./components/AiFeed/Import";

import TabList from "./components/TabManagement/List";
import TabEdit from "./components/TabManagement/Edit";
import TabAdd from "./components/TabManagement/Add";
import CompanyClone from "./components/Company/CompanyClone";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import RaiseDispute from "./components/RaiseDispute/List";
import RaiseDisputeAdd from "./components/RaiseDispute/Add";
import RaiseDisputeEdit from "./components/RaiseDispute/Edit";

import AdvertisementList from "./components/Advertisement/List";
import AdvertisementEdit from "./components/Advertisement/Edit";
import AdvertisementAdd from "./components/Advertisement/Add";

import AdsCategoryList from "./components/AdsCategory/List";
import AdsCategoryAdd from "./components/AdsCategory/Add";
import AdsCategoryEdit from "./components/AdsCategory/Edit";

import CouponList from "./components/Coupon/List";
import CouponEdit from "./components/Coupon/Edit";
import CouponAdd from "./components/Coupon/Add";
import CouponView from "./components/Coupon/View";

import UserDisputeList from "./components/UserDispute/List";


import CmsList from "./components/Cms/List";
import CmsAdd from "./components/Cms/Add";
import CmsEdit from "./components/Cms/Edit";
import CmsView from "./components/Cms/View";


import GuideList from "./components/Guide/List";
import GuideEdit from "./components/Guide/Edit";
import GuideAdd from "./components/Guide/Add";

import GuideCategoryList from "./components/GuideCategory/List";
import GuideCategoryAdd from "./components/GuideCategory/Add";
import GuideCategoryEdit from "./components/GuideCategory/Edit";
import GuideSubCategoryList from "./components/GuideSubCategory/List";
import GuideSubCategoryAdd from "./components/GuideSubCategory/Add";
import GuideSubCategoryEdit from "./components/GuideSubCategory/Edit";

import './common/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" component={Forgetpassword} />
      <Route path="/reset_password/:key" component={Resetpassword} />
      <Route path="/company" element={<Company />} />
      <Route path="/company-add" element={< CompanyAdd />} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      <Route path="/font-edit/:ID" element={<FontEdit />} />
      <Route path="/fonts" element={<Fontlist />} />
      <Route path="/font-add" element={<FontAdd />} />

      <Route path="/user" element={<UserList/>} />
      <Route path="/user-add" element={< UserAdd/>} />
      <Route path="/user-edit/:ID" element={<UserEdit/>} />
      <Route path="/user-view/:ID" element={<UserView/>} />
      <Route path="/user-appt-view/:ID/:USER" element={<UserViewAppt/>} />

      <Route path="/rehabs" element={<RehabList/>} />
      <Route path="/rehabs-add" element={< RehabAdd/>} />
      <Route path="/rehabs-edit/:ID" element={<RehabEdit/>} />
      <Route path="/rehabs-view/:ID" element={<RehabView/>} />
      <Route path="/rehabs-appt-view/:ID/:REHAB" element={<RehabViewAppt/>} />
      <Route path="/gallery" element={<Gallery/>} />

      <Route path="/drivers" element={<DriverList/>} />
      <Route path="/drivers-add" element={<DriverAdd/>} />
      <Route path="/drivers-edit/:ID" element={<DriverEdit/>} />
      <Route path="/drivers-view/:ID" element={<DriverView/>} />
      <Route path="/drivers-appt-view/:ID/:DRIVER" element={<DriverViewAppt/>} />

      <Route path="/appointments" element={<AppointmentList/>} />
      <Route path="/appointment-view/:ID" element={<AppointmentView/>} />
      <Route path="/appointment-add" element={<AppointmentAdd/>} />
      <Route path="/appointment-assigned" element={<AppointmentAssigned/>} />
      <Route path="/appointment-observation" element={<AppointmentObservation/>} />
      <Route path="/appointment-assignedview/:ID" element={<AppointmentAssignedView/>} />
      <Route path="/appointment-observationview/:ID" element={<AppointmentObservationView/>} />

      
      <Route path="/membership" element={<MemList/>}  />
        <Route path="/membership-edit/:ID" element={<MemEdit/>} />
        <Route path="/membership-view/:ID" element={<MemView/>} />
        <Route path="/membership-add" element={<MemAdd/>} />

        <Route path="/membershipcategory" element={<MembershipCategoryList/>}  />
        <Route path="/membershipcategory-add" element={<MembershipCategoryAdd/>} />
        <Route path="/membershipcategory-edit/:ID" element={<MembershipCategoryEdit/>} />
      {/* <Route path="/user" element={UserList} />
      <Route path="/edit/:ID" element={UserEdit} />
      <Route path="/view/:ID" element={UserView} />
      <Route path="/user-add" element={UserAdd} />
      <Route path="/user-import" element={UserImport} /> */}

      <Route path="/templates" element={<TemplateList />} />
      <Route path="/template/add" element={<TemplateAdd/>} />
      <Route path="/templates-edit/:ID" element={<TemplateEdit />} />
      <Route path="/dashboard" element={<DashboardList />} /> 
      <Route path="/company-clone/:ID" element={<CompanyClone />} />
      <Route path="/faqcategory" element={<FaqCategoryList/>} />
      <Route path="/faqcategory-add" element={<FaqCategoryAdd/>} />
      <Route path="/faqcategory-edit/:ID" element={<FaqCategoryEdit/>} />  

      <Route path="/faq" element={<FaqList/>} />
      <Route path="/faq-add" element={<FaqAdd/>} />
      <Route path="/faq-edit/:ID" element={<FaqEdit/>} />
      <Route path="/faq-import" element={<FaqImport/>} />

      <Route path="/amenities" element={<AmenityList/>} />
      <Route path="/amenity-add" element={<AmenityAdd/>} />
      <Route path="/amenity-edit/:ID" element={<AmenityEdit/>} />

      <Route path="/vehicles" element={<VehicleList/>} />
      <Route path="/vehicle-add" element={<VehicleAdd/>} />
      <Route path="/vehicle-edit/:ID" element={<VehicleEdit/>} />
            
      <Route path="/aifeed" element={<AiFeedList/>} />
      <Route path="/aifeed-import" element={<AiImport/>} />
      <Route path="/tabmanagement" element={<TabList/>} />
        <Route path="/tabmanagement-edit/:ID" element={<TabEdit/>} />
        
        <Route path="/tabmanagement-add" element={<TabAdd/>} />

        <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
        <Route path="/dashboardmanagement-edit/:ID" element={<DashboardEdit/>} />
        {/* <Route path="/dashboardmanagement-add" component={<DashboardAdd/>} /> */}

      <Route path="/raiseDispute" element={<RaiseDispute />} />
      <Route path="/raiseDispute-add" element={<RaiseDisputeAdd />} />
      <Route path="/raiseDispute-edit/:ID" element={<RaiseDisputeEdit />} />

      <Route path="/advertisement" element={<AdvertisementList/>} />
      <Route path="/advertisement-add" element={<AdvertisementAdd/>} />
      <Route path="/advertisement-edit/:ID" element={<AdvertisementEdit/>} />

      <Route path="/adscategory" element={<AdsCategoryList/>} />
      <Route path="/adscategory-add" element={<AdsCategoryAdd/>} />
      <Route path="/adscategory-edit/:ID" element={<AdsCategoryEdit/>} />
      
      <Route path="/coupon" element={<CouponList/>} />
      <Route path="/coupon-add" element={<CouponAdd/>} />
      <Route path="/coupon-edit/:ID" element={<CouponEdit/>} />
      <Route path="/coupon-view/:ID" element={<CouponView/>} />

      <Route path="/userDispute" element={<UserDisputeList/>} />

      <Route path="/Cms" element={<CmsList/>} />
      <Route path="/Cms-add" element={<CmsAdd/>} />
      <Route path="/Cms-edit/:ID" element={<CmsEdit/>} />
      <Route path="/Cms-view/:ID" element={<CmsView/>} />

      <Route path="/guide" element={<GuideList/>} />
      <Route path="/guide-edit/:ID" element={<GuideEdit/>} />
      <Route path="/guide-add" element={<GuideAdd/>} />

      <Route path="/guidecategory" element={<GuideCategoryList/>} />
      <Route path="/guidecategory-add" element={<GuideCategoryAdd/>} />
      <Route path="/guidecategory-edit/:ID" element={<GuideCategoryEdit/>} /> 

      <Route path="/guidesubcategory" element={<GuideSubCategoryList/>} />
      <Route path="/guidesubcategory-add" element={<GuideSubCategoryAdd/>} />
      <Route path="/guidesubcategory-edit/:ID" element={<GuideSubCategoryEdit/>} />
      

    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
