import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          driverlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   this.listloadedData();
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
   axios.post(apiUrl+"driver/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  driverlist: res.data, totalRecord: res.data.records_count}, function() {
           this.driverlist();
         });
     }
   }); 
}

  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : 1,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };     
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           driver_id  :id
//         };
//         axios.post(apiUrl+"driver/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  driverlist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.driverlist();
//               });
//           }
//         }); 
// }



activatedriver(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          driver_id  :this.state.selectid,
		  status: stat
        };
        axios.post(apiUrl+"driver/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}

confirmPopup = (id, status) => {
  
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activatedriver();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"driver/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
  driverlist() {

    var driverlist = this.state.driverlist;
    if (driverlist != "undefined" && driverlist != null) {
      if (driverlist.status == "success") {
        if (Object.keys(driverlist).length > 0) {
          const driverlistDetails = driverlist.driverlist.map(
            (driverlist, Index) => {
              let sno = Index+1;
              if(driverlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var mobile = ((driverlist.mobile == null)||(driverlist.mobile == '')) ?  driverlist.mobile = 'N/A' : driverlist.mobile;
              return (
                <tr key={driverlist.rehap_id}>
                <td>{sno}</td>
				 
                
                  <td>{driverlist.firstname}</td>
                  <td>
                  {driverlist.center_name}
                  
                  </td>
                  <td>{mobile}</td>
                  <td>{driverlist.username}</td>
                  <td>{driverlist.orgpwd}</td>
               
                 

				        
                 
				        
                  <td>{lang.Capitalize(status)}</td>
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/drivers-view/'+driverlist.driver_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/drivers-edit/'+driverlist.driver_id}} className="" title="Edit">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, driverlist.driver_id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, driverlist.driver_id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}          
                  </td>
                </tr>
              );
            }
          );
          return driverlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the driver?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="drivers" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
          <h3>{this.state.totalRecord} Driver{this.state.totalRecord>1 ? 's' : ''}</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/drivers-add'>Add &nbsp;|&nbsp;</a>
			 {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Driver</th>
			          <th>Assigned Rehab</th>
              
                {/* <th>Email</th> */}
                <th>Contact</th>
                {/* <th>Started Year</th> */}
                <th>Username</th>
                <th>Password</th> 

           
                
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.driverlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;