import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

const initialState = {
	        patient_name:'',
			applicant_name:'',
			gender:'',
			age:'',
			selectedDate:'',
			phoneno:'',
			email:'',
			status:'',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			selectedGender : '',
			selectedGenderValue : '',
			street:'',
			city:'',
			state:'',
			country:'',
			zipcode:'',
			rehablist:'',
			selectedOptionApplicant:'self',
			selectedrehabOption:'',
			selectedrehabvalue:'',

		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	  
       this.handleChange = this.handleChange.bind(this);

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlerehabChange = selectedrehabOption => {
        this.setState({selectedrehabOption:selectedrehabOption})
        this.setState({  selectedrehabvalue : selectedrehabOption.value});
    };
	handleGenderChange = selectedOption => {
        this.setState({selectedGender:selectedOption})
        this.setState({  gender : selectedOption.value});
    };
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}
handleDateChange = (e) => {
	
	this.setState({selectedDate:e.target.value})
  };
	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add appointment');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"user/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
		
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		var qs = require('qs');
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
		}
		let rehablists = [];
		axios.post(apiUrl+"rehab/rehablist",qs.stringify(postObject)).then(res => {
			if(res.data.status == "success"){
				let i=0;
				let rehablist = res.data.rehablist;
				
				for(i; i<rehablist.length; i++){
					rehablists[i] = { value: rehablist[i]['rehab_id'], label: rehablist[i]['center_name'] };
				}
				this.setState({rehablist:rehablists})
			}       
		});
		

    }
	
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					domain_id       : formPayload.selectedDomainvalue,
					patient_name     : formPayload.patient_name,
					applicant_name     : formPayload.applicant_name,
					gender    : formPayload.gender,
					age     : formPayload.age,
					email        : formPayload.email,
					appointment_date      : formPayload.selectedDate,
					phoneno     : formPayload.phoneno,
					street     : formPayload.street,
					city     : formPayload.city,
					state     : formPayload.state,
					country     : formPayload.country,
					zipcode     : formPayload.zipcode,
					rehab_center     : formPayload.selectedrehabvalue,
					purpose_book     : formPayload.purpose_book,
					status       :  status,
				};
				console.log('domain_id',postObject);
				axios.post(apiUrl+"appointment/add",qs.stringify(postObject)).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/appointments');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}
	isUSZipCode(zipCode) {
		// Regular expression pattern for validating US ZIP codes
		const zipCodePattern = /^\d{5}(-\d{4})?$/;
		return zipCodePattern.test(zipCode);
	  }
	validateForm() {
		const {selectedrehabvalue,patient_name,applicant_name, gender,age,selectedDate,email, phoneno,selectedDomainvalue,street,city,state,country,zipcode,purpose_book} = this.state;
		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;
		const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

             var maxCharacterLimit=20;
			 if (!selectedrehabvalue) {
				errors++;
				$('.errorrehab').html('<span class="errorspan">Please select the field</span>');
		   }else if(selectedrehabvalue){
				$('.errorrehab').html('');
			}
		if (!applicant_name) {
			errors++;
			$('.errorapplicant_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(applicant_name){
			if (!nameRegex.test(applicant_name)) {
				errors++;
			$('.errorapplicant_name').html('<span class="errorspan">Please enter the valid applicant name</span>');
			}else if (applicant_name.length <= maxCharacterLimit) {
				$('.errorapplicant_name').html('');
	
			  }else{
				errors++;
				$('.errorapplicant_name').html('Applicant name should be less than 20 characters');
	
			  }
			
		}
		if (!patient_name) {
			errors++;
			$('.errorpatient_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(patient_name){
			if (!nameRegex.test(patient_name)) {
				errors++;
			$('.errorpatient_name').html('<span class="errorspan">Please enter the valid patient name</span>');
			}else if (patient_name.length <= maxCharacterLimit) {
				$('.errorpatient_name').html('');
	
			  }else{
				errors++;
				$('.errorpatient_name').html('Applicant name should be less than 20 characters');
	
			  }
			
		}
		if (!age) {
			errors++;
			$('.errorage').html('<span class="errorspan">Please fill the field</span>');
		}else if(age){
			if (/^[0-9]*\.?[0-9]*$/.test(age)) {
				$('.errorage').html('');
			  }else{
				errors++;
			$('.errorage').html('<span class="errorspan">Please enter the valid age</span>');
			  }

			
		}
		if (!gender) {
			errors++;
			$('.errorgender').html('<span class="errorspan">Please select the field</span>');
	   }else if(gender){
			$('.errorgender').html('');
		}
		if (!purpose_book) {
			errors++;
			$('.errorpurpose_book').html('<span class="errorspan">Please fill the field</span>');
	   }else if(gender){
			$('.errorpurpose_book').html('');
		}
		if (!selectedDate) {
			errors++;
			$('.errorselectedDate').html('<span class="errorspan">Please select the field</span>');
	   }else if(selectedDate){
		if (selectedDate >= today) {
			$('.errorselectedDate').html('');
		}else{
			errors++;
			$('.errorselectedDate').html('Please select a future date');
		}
			
		}
		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		

		
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

				if (!street) {
					errors++;
					$('.errorstreet').html('<span class="errorspan">Please fill the field</span>');
			   }else if(street){
					$('.errorstreet').html('');
				}
				if (!city) {
					errors++;
					$('.errorcity').html('<span class="errorspan">Please fill the field</span>');
			   }else if(city){
					$('.errorcity').html('');
				}
				if (!state) {
					errors++;
					$('.errorstate').html('<span class="errorspan">Please fill the field</span>');
			   }else if(state){
					$('.errorstate').html('');
				}
				if (!country) {
					errors++;
					$('.errorcountry').html('<span class="errorspan">Please fill the field</span>');
			   }else if(country){
					$('.errorcountry').html('');
				}
				if (!zipcode) {
					errors++;
					$('.errorzipcode').html('<span class="errorspan">Please fill the field</span>');
			   }else if(zipcode){
				if(this.isUSZipCode(zipcode)){
					$('.errorzipcode').html('');
				}else{
					errors++;
					$('.errorzipcode').html('Please enter valid postal code');
				}
				}
				

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
	handleRadioChange = (event) => {
		
		this.setState({selectedOptionApplicant:event.target.value})
	  };
  render() {
  	
  	const {rehablist,selectedOption,selectedDomainOption,selectedGender,selectedDate,selectedrehabOption} = this.state;

console.log(selectedDomainOption,"selectedDomainOption")
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="appointments" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/appointments">Back</a>
			</div>
			<div className="title">
				<h4>Add Appointment</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			
			<div className="form-group ">
					<label>Patient Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="patient_name" onChange={this.handleInputChange} className="form-control" value={this.state.patient_name} autoComplete="off"/>
					<div className="errorpatient_name"></div>
				</div>
				
				<div className="form-group">
					<label>Age<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="age" onChange={this.handleInputChange} className="form-control" value={this.state.age} autoComplete="off"/>
					<div className="errorage"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				<div className="form-group">					
					<label>Rehab Centers<span class="required" style={{ color: "red" }} > </span></label>
						   <Select 
						   value={selectedrehabOption}
                            options={rehablist} 
                            onChange={this.handlerehabChange.bind(this)}
                            />
							<div className="errorrehab"></div>
							
	           </div>
			   <div className="form-group">
					<label>Purpose of Book<span class="required" style={{ color: "red" }} >*</span></label>
				    <textarea type="text" name="purpose_book" onChange={this.handleInputChange} className="form-control" value={this.state.purpose_book} autoComplete="off"/>
					<div className="errorpurpose_book"></div>
				</div>
				<div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 
				 
				
				</div>
			<div className="form-right">
			
				<div className="form-group">
					<label>Applicant Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="applicant_name" onChange={this.handleInputChange} className="form-control" value={this.state.applicant_name} autoComplete="off"/>
					<div className="errorapplicant_name"></div>
				</div>
			<div className="default-font">
				<div className="form-group">					
					<label>Gender<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedGender}
                            options={lang.common.gender_option} 
                            onChange={this.handleGenderChange}
                            />
				</div>
				</div>
				
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				
				<div className="form-group">
					<label>Date<span class="required" style={{ color: "red" }} >*</span></label>
				    <input
        type="date" className="form-control"
        id="datePicker"
        value={selectedDate}
        onChange={this.handleDateChange}
      />
					<div className="errorselectedDate"></div>
				</div>  
				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			  
                
				</div>
			</div>
			<div className="title">
					<h4> Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
					<div className="errorstreet"></div>
				</div>
				<div className="form-group">
						<label>State<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
						<div className="errorstate"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="zipcode" onChange={this.handleInputChange} className="form-control" value={this.state.zipcode} />
					<div className="errorzipcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
					<div className="errorcity"></div>
				</div>
				<div className="form-group">
					<label>Country<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
					<div className="errorcountry"></div>
				</div> 
					
				</div>

			</div>
			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);